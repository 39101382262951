.layout-container {
  position: relative;
  min-height: 100vh;
}

.content-layout {
  margin-top: 80px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}
