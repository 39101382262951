.navbar-header-sm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  visibility: hidden;
  background-color: #fff;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 80px;
  position: fixed;
  width: 100%;
  color: #397890;
  top: 0;
  border-bottom: 2px solid #1e7a92;
  box-shadow: 0 0 2px 1px #a9a9a9;
  z-index: 2;
}

.navbar-header-md {
  background-color: #fff;
  padding: 0;
  height: 80px;
  position: fixed;
  width: 100%;
  color: #397890;
  top: 0;
  border-bottom: 2px solid #1e7a92;
  box-shadow: 0 0 2px 1px #a9a9a9;
  z-index: 2;
}

.logo-container {
  /* padding: 20px; */
  align-self: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 30px;
  padding-left: 40px;
  max-width: 300px;
  min-width: 180px;
}

.navbar-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: #fff;
  height: 80px;
}

.navbar-container {
  display: flex;
}

.navbar-header-top {
  padding: 5px 10px;
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 14px;
  background-color: #032540;
  border-bottom: 1px solid #1e7a92;
}
.navbar-header-bottom {
  padding: 5px 10px;
  display: flex;
  flex: 1;
  font-size: 16px;
  background-color: #032540;
  column-gap: 1rem;
  font-weight: 700;
}

.navbar-header-bottom a.link {
  color: #fff;
}
.navbar-header-bottom a:hover {
  color: #0056b3;
}

.navbar-header-bottom a:focus {
  color: #8a2bc8;
}

.link-active {
  color: #8a2bc8;
}

.menu-overlay {
  display: flex;
  justify-content: center;
  row-gap: 20px;
  transition: left 0.25s ease-out;
  position: fixed; /* Sit on top of the page content */
  visibility: hidden;
  max-width: 250px;
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff; /* Black background with opacity */
  z-index: 3; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.menu-overlay-content {
  position: relative;
  max-width: 250px;
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

.menu-link-overlay,
.link {
  display: flex;
  flex-direction: column;
  color: #397890;
}

@media only screen and (max-width: 768px) {
  .navbar-header-md {
    display: none;
  }

  .navbar-header-sm {
    visibility: visible;
  }

  .menu-overlay {
    visibility: visible;
  }

  .logo-container {
    max-width: 100px;
    padding: 0;
  }
}
