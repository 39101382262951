@import "../theme//layout.css";
@import "../theme//Home.css";
@import "../theme/Navbar.css";
@import "../theme//Agenda.css";

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url("../static/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: bold;
  font-weight: 400;
  font-display: auto;
  src: url("../static/fonts/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: bold;
  font-weight: 700;
  font-display: auto;
  src: url("../static/fonts/Montserrat-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
