.agenda-container {
  background-color: rgb(247, 247, 247);
  padding-top: 2rem;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
}
.btn-agenda {
  border: 3px solid #000;
  width: 100px;
  height: 40px;
  border-radius: 30px;
}
.btn-text {
  font-weight: 400;
}

.btn-agenda-active {
  background-color: #003454;
  border: 3px solid #000;
  width: 100px;
  height: 40px;
  border-radius: 30px;
  color: #fff;
}

.input-search-agenda {
  width: 220px;
  height: 40px;
  border: 3px solid #000;
  padding: 12px;
  border-radius: 30px;
}

.input-search-agenda::placeholder {
  font-weight: 700;
  color: #757575;
  padding-left: 12px;
}

.agenda-header-text {
  text-align: center;
  font-size: 40px;
  color: #397890;
  font-weight: 700;
}

.agenda-search-engine {
  display: flex;
  column-gap: 20px;
  margin-top: 18px;
}
.agenda-select-mode {
  display: flex;
  column-gap: 20px;
  margin-top: 18px;
}

.agenda-card {
  background-color: #fff;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-sizing: border-box;
  border-radius: 25px;
}

.agenda-avartar {
  min-width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: aquamarine;
}

.agenda-avartar ::marker {
  color: #000;
  width: 20px;
}

.row-avartar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  word-wrap: break-all;
  padding-top: 8px;
  padding-bottom: 12px;
  padding-left: 2rem;
}

.agenda-text {
  font-size: 40px;
  color: #397890;
  font-weight: 700;
}

.agenda-text-label {
  font-size: 32px;
  color: #397890;
  font-weight: 700;
  line-height: 34px;
  padding-top: 8px;
  padding-bottom: 12px;
}

.agenda-text-day {
  font-size: 40px;
  color: #397890;
  font-weight: 700;
  line-height: 34px;
  padding-top: 8px;
  padding-bottom: 12px;
}

.agenda-text-time {
  font-size: 18px;
  color: #003454;
}

.agenda-text-content {
  color: #397890;
  font-weight: 300;
  font-size: 1rem;
}

.agenda-content-text-1 {
  color: #397890;
  font-weight: 400;
  font-size: 18px;
}

.agenda-content-text-2 {
  color: #397890;
  font-size: 18px;
  font-weight: 300;
}

.extra-content {
  padding-left: 2rem;
  padding-bottom: 20px;
}

li::marker {
  color: #0056b3;
}

.hyperlink-agenda {
  color: #007bff;
  word-wrap: break-word;
}

.hyperlink-agenda:hover {
  color: #397890;
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .agenda-container {
    padding: 1rem;
  }
  .agenda-engine-paginate {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .input-search-agenda {
    width: 180px;
  }

  .agenda-header-text {
    font-size: 30px;
    padding-top: 2rem;
  }
  .agenda-text {
    font-size: 30px;
  }
  .agenda-text-label {
    font-size: 26px;
  }

  .extra-content {
    padding-left: 1rem;
  }
  .row-avartar {
    padding-left: 1rem;
  }
}
